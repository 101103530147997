/* .bgSizeCover {
  background-image: url(https://www.mozilla.org/media/img/logos/firefox/logo-quantum.9c5e96634f92.png);
  background-size: cover;
  width: 160px;
  height: 160px;
  border: 2px solid;
  color: pink;
  resize: both;
  overflow: scroll;
} */

html {
  background: url(./assets/landing-v2.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.landing-hero {
  background-color: rgba(0, 0, 0, 0.7);
}

.main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
